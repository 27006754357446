import { useCallback, useEffect, useRef } from 'react';
import debounce from 'lodash.debounce';
const defaultOptions = {
    leading: false,
    trailing: true,
};
/**
 * Accepts a function and returns a new debounced yet memoized version of that same function that delays
 * its invoking by the defined time.
 * If time is not defined, its default value will be 250ms.
 */
const useDebouncedCallback = (fn, dependencies = [], wait = 250, options = defaultOptions) => {
    const debounced = useRef(debounce(fn, wait, options));
    useEffect(() => {
        debounced.current = debounce(fn, wait, options);
    }, [fn, wait, options]);
    return useCallback(debounced.current, dependencies);
};
export default useDebouncedCallback;
