import { useEffect } from 'react';
import useHandlerSetterRef from './shared/useHandlerSetterRef';
/**
 * Returns a callback setter for a function to be performed when the component did mount.
 */
const useDidMount = (callback) => {
    const [handler, setHandler] = useHandlerSetterRef(callback);
    useEffect(() => {
        if (handler.current) {
            handler.current();
        }
    }, []);
    return setHandler;
};
export default useDidMount;
