import { useEffect } from 'react';
import safeHasOwnProperty from './shared/safeHasOwnProperty';
import createCbSetterErrorProxy from './shared/createCbSetterErrorProxy';
import useHandlerSetterRef from './shared/useHandlerSetterRef';
const assignDragEventOnMount = (targetRef, handlerRef, eventName) => {
    useEffect(() => {
        const cb = (dragEvent) => {
            if (handlerRef && handlerRef.current) {
                handlerRef.current(dragEvent);
            }
        };
        if (targetRef.current) {
            targetRef.current.addEventListener(eventName, cb);
        }
        return () => {
            if (targetRef.current) {
                targetRef.current.removeEventListener(eventName, cb);
            }
        };
    }, []);
};
/**
 * Returns an object of callback setters to handle the drag-related events.
 * It accepts a DOM ref representing the events target (where attach the events to).
 *
 * Returned callback setters: `onDrag`, `onDrop`, `onDragEnter`, `onDragEnd`, `onDragExit`, `onDragLeave`,
 * `onDragOver`, `onDragStart`;
 */
const useDragEvents = (targetRef, setDraggable = true) => {
    const [onDrag, setOnDrag] = useHandlerSetterRef();
    const [onDrop, setOnDrop] = useHandlerSetterRef();
    const [onDragEnter, setOnDragEnter] = useHandlerSetterRef();
    const [onDragEnd, setOnDragEnd] = useHandlerSetterRef();
    const [onDragExit, setOnDragExit] = useHandlerSetterRef();
    const [onDragLeave, setOnDragLeave] = useHandlerSetterRef();
    const [onDragOver, setOnDragOver] = useHandlerSetterRef();
    const [onDragStart, setOnDragStart] = useHandlerSetterRef();
    if (targetRef !== null && !safeHasOwnProperty(targetRef, 'current')) {
        return createCbSetterErrorProxy('Unable to assign any drag event to the given ref');
    }
    useEffect(() => {
        if (setDraggable && targetRef.current && !targetRef.current.hasAttribute('draggable')) {
            targetRef.current.setAttribute('draggable', String(true));
        }
    }, []);
    assignDragEventOnMount(targetRef, onDrag, 'drag');
    assignDragEventOnMount(targetRef, onDrop, 'drop');
    assignDragEventOnMount(targetRef, onDragEnter, 'dragenter');
    assignDragEventOnMount(targetRef, onDragEnd, 'dragend');
    assignDragEventOnMount(targetRef, onDragExit, 'dragexit');
    assignDragEventOnMount(targetRef, onDragLeave, 'dragleave');
    assignDragEventOnMount(targetRef, onDragOver, 'dragover');
    assignDragEventOnMount(targetRef, onDragStart, 'dragstart');
    return Object.freeze({
        onDrag: setOnDrag,
        onDrop: setOnDrop,
        onDragEnter: setOnDragEnter,
        onDragEnd: setOnDragEnd,
        onDragExit: setOnDragExit,
        onDragLeave: setOnDragLeave,
        onDragOver: setOnDragOver,
        onDragStart: setOnDragStart,
    });
};
export default useDragEvents;
