import { useEffect } from 'react';
import useHandlerSetterRef from './shared/useHandlerSetterRef';
/**
 * Returns a callback setter for a callback to be performed when the component will unmount.
 */
const useWillUnmount = (callback) => {
    const [handler, setHandler] = useHandlerSetterRef(callback);
    useEffect(() => () => {
        if (handler) {
            handler.current();
        }
    }, []);
    return setHandler;
};
export default useWillUnmount;
