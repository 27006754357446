import { useCallback, useState } from 'react';
const maybeState = (state, defaultValue) => (state !== null && state !== void 0 ? state : defaultValue);
/**
 * Returns a safe state by making sure the given value is not null or undefined
 */
const useDefaultedState = (defaultValue, initialState) => {
    const [state, setState] = useState(maybeState(initialState, defaultValue));
    const setStateSafe = useCallback((nextState) => {
        setState(maybeState(nextState, defaultValue));
    }, [setState]);
    return [maybeState(state, defaultValue), setStateSafe];
};
export default useDefaultedState;
